.project {
  padding: 2em;
  margin: 0 auto;
  text-align: center;
  box-shadow: var(--shadow);
  transition: transform 0.2s linear;
}

.project:hover {
  transform: translateY(-7px);
}

.project__description {
  margin-top: 1em;
}

.project__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.2em 0;
}

.project__stack-item {
  margin: 0.5em;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
}

.project .link--icon {
  margin-left: 0.5em;
}

.underline {
  padding-top: 15px;
  margin-top: 15px;
}

.proj-pic {
  width: 200px;
  height: 100px;
  object-fit: cover;
  border-radius: 20px;
  margin-top: 10px;
}
