.about {
  flex-direction: column;
  margin-top: 3em;
}

.about__name {
  color: var(--clr-primary);
}

.about__role {
  margin-top: 1.2em;
}

.about__desc {
  font-size: 1rem;
  max-width: 600px;
}

.about__desc,
.about__contact {
  margin-top: 2.4em;
}

.about .link--icon {
  margin-right: 3em;
  margin-left: 3em;
}

.about .btn--outline {
  margin-right: 1em;
}

@media (max-width: 600px) {
  .app .about {
    align-items: flex-start;
    margin-top: 2em;
  }
}

.me {
  width: 40vw;
  height: 20vw;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 20px;
}
